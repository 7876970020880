
.card-one.nonfatal-error-card {
    border: 1px solid #fade8c;
    .pass-badge {
        color: #424242;
        background-color: #d7a000  !important;
    }
}

.card-one.fatal-error-card {
    border: 1px solid #E85353;
    .pass-badge {
        background-color: #B11717 !important;
    }
}