.no-order-state {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    img {
        width: 150px;
        opacity: 0.5;
    }
}

@media only screen and (max-width: 480px) {
    .no-order-state {
        position: static;
        padding: 8rem 0;
        transform: none;
        -webkit-transform: none;
    }
}