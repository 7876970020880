.audit-detail-page {
    .page-top-header {
        background-color: #fff;
        padding: 10px 20px;
        border-top: 1px solid #E5E6ED;
        border-bottom: 1px solid #E5E6ED;
        box-shadow: 0 1px 1px rgba(33, 40, 48, 0.01), 0 4px 4px rgba(33, 40, 48, 0.01), 0 16px 16px rgba(33, 40, 48, 0.01);
    }

    .doughnut-chart-view {
        margin: 0 auto;
        height: 370px;

        .doughnut-chart-value {
            position: absolute;
            top: 55%;
            left: 50%;
            text-align: center;
            font-size: 18px;
            font-weight: bold;
            transform: translate(-50%, -50%);
            -webkit-transform: translate(-50%, -50%);
        }
        canvas {
            margin-top: -4rem;
        }
    }

    .chart-fatal-error {
        margin-top: -3rem;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    .sub-error-card {
        .sub-error-list {
            justify-content: end;
        }
    }


}

@media screen and (max-width: 1024px) {
    .audit-detail-page {
        .doughnut-chart-view {
            height: 200px ;
            .doughnut-chart-value {
                font-size: 12px;
            }
            canvas {
                margin-top: -3rem;
            }

            .chart-overall-score {
                font-size: 14px;
            }
        }
    }
}

// MOBILE VIEW > 280 DEICE
@media screen and (max-width: 480px) {
   .audit-detail-page {
        padding-top: 0 !important;

        .doughnut-chart-view {
            height: 350px;
            canvas {
                width: 100% !important;
            }

            .doughnut-chart-value {
                font-size: 20px;
            }
        }

        .top-header-bttns {
            button {
                font-size: 12px;
            }
        }

        .sub-error-card {
            .card-header {
                display: block;

                h3 {
                    margin-bottom: 5px;
                }
            }

            .card-body {
                .custom-col {
                    margin-top: 5px;
                }

                .sub-error-list {
                    margin-bottom: 20px;
                }
            }

        }

        .error-status-pass {
            position: absolute;
            right: 0;
            top: 6px;
        }
    }
}