body {
    color: #000 !important;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    ul {
        margin: 0;
        padding: 0;
    }

    a {
        cursor: pointer !important;
    }

    .end-10 {
        right: 10px;
    }

    .end-20 {
        right: 30px;
    }

    .end-30 {
        right: 30px;
    }

    .z-index-2 {
        z-index: 2 !important;
    }

    .z-index-10 {
        z-index: 10 !important;
    }

    .z-index-99 {
        z-index: 99 !important;
    }

    .overflow-y {
        overflow-y: scroll;
    }

    .cursor-pointer {
        cursor: pointer;
    }

    .right-10 {
        right: 10px !important;
    }

    .top-10 {
        top: 10px !important;
    }

    .pl-0 {
        padding-left: 0 !important;
    }

    .pr-0 {
        padding-right: 0 !important;
    }

    .w-50 {
        width: 50%;
    }

    .bg-opacity-10 {
        --bs-bg-opacity: 0.10;
    }

    .text-left {
        text-align: left !important;
    }

    .text-right {
        text-align: right !important;
    }

    .flex-100 {
        flex: 0 100% !important;
    }

    img {
        object-fit: cover;

        &.object-fit-contain {
            object-fit: contain;
        }
    }

    .lg-flex-space-between {
        justify-content: space-between;
    }

    .offcanvas-backdrop {
        z-index: 1045;
    }

    // 
    .login-content {
        left: 0;
        padding: 20px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        width: 70%;
        h2 {
            font-size: 1.2vw;
        }
       h1 {
            font-size: 3.2vw;
        }
    }

    // TABLE
    table {

        thead,
        tbody {
            tr {

                th,
                td {
                    vertical-align: middle;
                }
            }
        }
    }

    .error-10 {
        background-color: #FFE599;
    }

    .minor-bg {
        background-color: #FBD38D;
    }

    .moderate-bg {
        background-color: #F6BB7D;
    }

    .major-bg {
        background-color: #EC8D5E;
    }

    .extreme-bg {
        background-color: #CC0000;
    }

    // BTN ICON
    .btn-icon,
    .custom-btn-icon {
        width: 30px;
        height: 30px;

        &.dropdown-toggle {
            &::after {
                display: none;
            }
        }
    }

    // USER PROFILE
    .user-profile {
        align-items: center;
        background-color: #c3c3c3;
        border-radius: 100%;
        color: #424242;
        display: flex;
        height: 100%;
        justify-content: center;
        width: 100%;

        i {
            font-size: 20px;
            line-height: 1.4;
        }
    }

    // TWO THREE LINE ELLIPSIS
    .two-line-ellipsis,
    .three-line-ellipsis {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;

        &.two-line-ellipsis {
            -webkit-line-clamp: 2;
        }

        &.three-line-ellipsis {
            -webkit-line-clamp: 3;
        }
    }

    // PAGE IN TABS
    .page-in-tabs {
        padding: 10px;

        .nav-pills {
            display: block;

            .nav-item {
                margin-bottom: 5px;
                width: 100%;

                .nav-link {
                    align-items: center;
                    color: #000;
                    display: flex;

                    i {
                        font-size: 20px;
                        line-height: 1;
                        margin-right: 12px;
                        opacity: .85;
                        width: 18px;
                    }

                    &:hover,
                    &.active {
                        background-color: #f3f3f3;
                        color: #506fd9;
                    }
                }
            }
        }
    }

    // CUSTOMER ADDRESS
    .custom-dropdown-wrapper {
        .custom-drop-down {
            position: relative;

            .dropdown-icon {
                position: absolute;
                left: 0;
                top: 0;
                width: 40px;
                bottom: 0;
                z-index: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #F5F5F5;
                border: 1px solid #cccccc;
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
                transition: 0.15s ease-in-out;
                -webkit-transition: 0.15s ease-in-out;

            }

            .react-select-class {
                padding-left: 35px;
            }

            .css-1hb7zxy-IndicatorsContainer {
                display: none;
            }

            .form-control {
                padding-left: 45px;

                &:focus {
                    border-color: #424242;
                }
            }

            &:focus-within {
                .dropdown-icon {
                    border-color: #424242;
                }
            }
        }

        .options-container {
            position: absolute;
            overflow: auto;
            z-index: 9;
            top: 4.5rem;
            left: 0;
            width: 100%;
            max-height: 250px;
            padding: 8px;
            border-radius: 7px;
            background: #fff;
            border: 1px solid #e9e9e9;
            box-shadow: 0 1px 2px rgb(204, 204, 204);

            .option {
                position: relative;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0.625rem;

                &:hover {
                    background-color: #f3f6ff;
                }
            }
        }


    }

    // OFF CANVAS CSS BEGIN
    .offcanvas-header {
        border-bottom: 0;
        z-index: 10;
        box-shadow: 0px 1px 5px 1px rgba(64, 60, 67, 0.16);
    }

    // OFFCANVAS END
    .offcanvas.offcanvas-end {
        &.off-canvas-custom-width {
            width: 45%;

            .offcanvas-header {
                height: 72px;
                padding: 1rem 1.5rem;
            }

            .offcanvas-body {
                padding: 1rem 1.5rem;
            }
        }

        .custom-offcanvas-header {
            .btn-close {
                position: absolute;
                top: 20px;
                right: 20px;
            }
        }

        .offcanvas-footer {
            display: flex;
            align-items: center;
            position: sticky;
            bottom: 0;
            left: 0;
            right: 0;
            height: 70px;
            padding: 1rem 1.5rem;
            justify-content: space-between;
            border-top: 1px solid rgb(204, 210, 218);
            box-shadow: 0 1px 1px rgba(33, 40, 48, .01), 0px -1px 4px rgba(33, 40, 48, .01), 0 16px 16px rgba(33, 40, 48, .01);
        }
    }

    // COMMON TABS
    .common-tabs {
        background-color: transparent;

        .nav-item {
            .nav-link {
                color: #343434;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 0;
                position: relative;
                text-transform: capitalize;
                border-bottom: 1px solid transparent;
                background-color: transparent;

                .badge-length-web {
                    color: #ffffff;
                    margin-left: 5px;
                    background-color: #eb7782;
                }

                i {
                    font-size: 22px;
                }

                &:hover,
                &.active {
                    color: #dc3545;
                    font-weight: 600;
                    border-bottom: 2px solid #dc3545;
                    background-color: transparent;

                    .badge-length-web {
                        color: #ffffff;
                        background-color: #dc3545;
                    }
                }
            }

        }


        &.table-state-tabs {
            .nav-item {
                .nav-link {

                    &:hover,
                    &.active {
                        color: #000;
                    }
                }
            }
        }

        .custom-tab-body {
            margin-top: 20px;
        }
    }

    .form-control:disabled,
    .gridjs-input:disabled {
        background-color: #dddddd;
        opacity: 0.5;
    }

    // PVERALL SCORE VIEW
    .overall-score-view {
        position: absolute;
        left: 50%;
        bottom: 0;
        text-align: center;
        transform: translate(-50%, -20%);
        -webkit-transform: translate(-50%, -20%);
    }

    // OVERALL SCORE PROGRESS
    .overall-score-progress {
        .progress {
            height: 15px;

            .progress-bar {
                text-align: left;

                span {
                    padding-left: 5px;
                    display: block;
                }
            }
        }
    }

    // ACTIVITY LOG TIMELINE
    .activity-log-timeline {
        .activity-item {
            padding-left: 30px;

            &::before {
                top: 0;
                left: -7px;
                width: 12px;
                height: 12px;
            }

            &.success-class {
                &::before {
                    background-color: #afafaf;
                }
            }

            &.danger-class {
                &::before {
                    background-color: #afafaf;
                }
            }
        }
    }

    // NO ERRORS
    .no-errors {
        padding: 50px 0;
        text-align: center;
    }

    // WIZARD STEPPER IN PROJECT BUILDER PAGE
    .form-step-content {
        .step-label {
            align-items: center;
            display: flex;
            margin-bottom: 5px;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
            position: relative;
            width: 100%;

            .icon-default {
                display: block;
                font-size: 20px;
            }

            .icon-active,
            .icon-completed {
                display: none;
                font-size: 20px;
            }

            &.active {
                background-color: #f7f7f7;

                .icon-default {
                    display: none;
                }

                .icon-active {
                    display: block;
                    color: #424242;
                }
            }

            &.completed {
                background-color: #f3f3f3;

                .icon-default {
                    display: none;
                }

                .icon-completed {
                    display: block;
                    color: #16a34a;
                }
            }
        }
    }

    // REFERENCE FIELD SWITCH
    .reference-field-switch {
        .form-switch {
            .form-check-input {
                width: 3em;
                height: 1.5em;
                cursor: pointer;
            }
        }
    }

    // ADD ERROR LIST
    .add-error-list {
        .form-check {
            margin-bottom: 10px;

            .form-check-input {
                width: 1.5em;
                height: 1.5em;
                cursor: pointer;

                &:focus {
                    box-shadow: none;
                }
            }

            label {
                vertical-align: sub;
                padding-left: 10px;
                cursor: pointer;
            }
        }

    }

    // TABLE SHIMMER EFFECT
    .table-shimmer-effect {
        table {
            tr {
                td {
                    border: 0;

                    .table-shimmer {
                        background: #f6f7f8;
                        background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
                        background-repeat: no-repeat;
                        background-size: 1000px 100%;
                        display: inline-block;
                        position: relative;
                        overflow: hidden;
                        padding: 12px 0;

                        &::after {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 0;
                            height: 100%;
                            width: 100%;
                            background: inherit;
                            animation: tableShimmer 1.5s infinite linear;
                        }

                        @keyframes tableShimmer {
                            0% {
                                background-position: -1000px 0;
                            }

                            100% {
                                background-position: 1000px 0;
                            }
                        }
                    }
                }
            }
        }
    }

    .table-row-shimmer {
        display: table-row;
    }

    .table-cell-shimmer {
        display: table-cell;
        padding: 10px;
        height: 20px;
        border-bottom: 1px solid #ddd;
    }

    // CARD SHIMMER EFFECT
    .placeholder-glow {
        .placeholder {
            opacity: 0.1;
            animation: placeholder-glow 0.75s ease-in-out infinite;
        }
    }

    // ERRPR COMMENT
    .error-comment {
        background-color: #F5F5F5;
    }

    // ADD ERROR LISTS
    .add-error-lists {
        &:last-child {
            margin-bottom: 0 !important;
        }
    }

    .error-category-table {
        &:last-child {
            .input-group {
                margin-bottom: 10px;
            }
        }
    }

    .error-tick-check {
        .form-check-input {
            width: 1.5em;
            height: 1.5em;
            cursor: pointer;

            &:focus {
                box-shadow: none;
            }
        }
    }

    // ERRPR OPPRTUNITIES COLLAPSE
    .error-opportunities-collapse {
        .card {
            overflow: hidden;
            margin-bottom: 10px;

            // border: 1px solid #e0e0e0;
            .card-header {
                justify-content: space-between;
            }

            // &:last-child {
            //     margin-bottom: 0;
            // }

            // .card-header {
            //     padding: 10px;
            //     justify-content: space-between;
            // }

            // &.active-collpase {
            //     border-color: #a29e9e;
            //     .card-header {
            //         background-color: #eeeeee;
            //     }
            // }
        }

    }

    // CARD CUSTIM HEIGHT
    .card {
        &.audit-details-card {
            height: calc(100vh - 160px);
        }

        &.audit-details-tabs-card {
            height: calc(100vh - 248px);

            .card-header {
                .nav-pills {
                    .nav-item {
                        width: 235px;

                        .nav-link {
                            height: 100%;
                            color: #797474;
                            border-radius: 0;
                            padding: 12px 15px;
                            position: relative;
                            background-color: #E3E3E3;

                            &::after {
                                z-index: 1;
                                position: absolute;
                                top: 100%;
                                left: 70%;
                                margin-left: -25%;
                                content: '';
                                width: 0;
                                height: 0;
                                transform: translateX(-50%);
                                -webkit-transform: translateX(-50%);
                                border-top: solid 5px transparent;
                                border-left: solid 5px transparent;
                                border-right: solid 5px transparent;
                            }

                            &.active {
                                color: #fff;
                                background-color: #343333;

                                &::after {
                                    border-top-color: #343333;
                                }

                                h2 {
                                    color: #F77C87;
                                }
                            }
                        }
                    }
                }
            }

            .card-body {
                .sub-error-view {
                    margin-bottom: 10px;
                    border-bottom: 1px solid #e4e4e4;

                    &:last-child {
                        margin-bottom: 0;
                        border-bottom: 0;
                    }

                    .error-point {
                        font-size: 14px;
                        width: 100%;
                        padding: 5px 5px;
                        border-radius: 3px;
                        background-color: #cccccc;
                    }
                }

                .pass-fail-dropdown {
                    .dropdown-toggle {
                        text-align: left;
                        padding: 2px 12px 2px 5px;
                        border-radius: 25px;
                        width: 65px;

                        &::after {
                            top: 2px;
                            right: 0;
                            font-size: 14px;
                            position: relative;
                        }
                    }
                }
            }
        }
    }

    // CUSTOM SCROLL
    .custom-scroll {
        &::-webkit-scrollbar {
            height: 1px;
            width: 2px;
        }

        &::-webkit-scrollbar-thumb {
            background: gray;
        }

        &::-webkit-scrollbar-track {
            background: #ddd;
        }

    }

    .error-status-toggle {
        position: relative;

        .toggle-wrapper {
            display: flex;
            justify-content: space-between;
            width: 100%;

            .toggle-text {
                position: absolute;
                top: 10px;
                color: #fff;
                font-size: 12px;
                cursor: pointer;
                font-weight: 600;
                transition: all 0.3s ease;
                -webkit-transition: all 0.3s ease;

                &.on {
                    left: 8px;
                    opacity: 1;
                    transition: left 0.3s ease;
                }

                &.off {
                    right: 8px;
                    opacity: 1;
                    transition: right 0.3s ease;
                }
            }
        }

        .form-check-input {
            width: 4.5em;
            height: 2em;
            cursor: pointer;
            background-color: #e85353;
            border-color: #e85353;
            background-size: 25px 18px;
            background-image: url("./assets/img/icons/toggle-circle.svg");
           

            &:checked {
                background-color: #098911;
                border-color: #098911;
                background-image: url("./assets/img/icons/toggle-circle.svg");
            }

            &:focus,
            &:active {
                outline: none;
                box-shadow: none;
                background-image: url("./assets/img/icons/toggle-circle.svg");
            }
        }

    }
}

@media only screen and (max-width: 1024px) {
    body {
        .lg-flex-space-between {
            justify-content: start;
        }

        // AUDIT SCPRE GAUGE
        .audit-score-gauge-card {

            .col-md-8,
            .col-md-4 {
                width: 100%;
            }

            .col-md-4 {
                margin-top: 0;
                margin-bottom: 2rem;
            }

            .fatal-error {
                font-size: 12px !important;
            }
        }

        // AUDIT ATA PAGE
        .audit-ata-page {
            .main-title {
                font-size: 12px !important;
            }

            .upload-document-card {
                button {
                    font-size: 12px;
                }

                .row {
                    .col-md-4 {
                        width: 100%;
                    }
                }
            }
        }


        .nonfatal-error-card,
        .fatal-error-card {
            .card-body {
                padding: 10px;

                .msg-item {
                    font-size: 12px !important;
                }
            }

            button {
                font-size: 12px;
            }
        }

        .card {
            &.audit-details-card {
                height: calc(100vh - 170px);
            }

            &.audit-details-tabs-card {
                height: calc(100vh - 300px);
            }
        }
    }
}

@media only screen and (max-width: 480px) {
    body {
        .card {

            &.audit-details-card,
            &.audit-details-tabs-card {
                height: auto;
            }

            &.audit-details-tabs-card {
                .card-header {
                    .nav-pills {
                        .nav-item {
                            width: 35%;
                        }
                    }
                }
            }
        }

    }
}